/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type downloadTemplateFileOptions = Record<string, any>;

export type downloadTemplateFileResponse = Promise<any>;

/**
 * @desc 下载编辑前台分类模板文件
 */
export function request(
  options?: downloadTemplateFileOptions,
): downloadTemplateFileResponse {
  const host = getEnvHost();
  const url = `${host}/product/bo/categoryView/template/variant`;
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  return sendRequest(url, fetchOption);
}
