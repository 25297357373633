export default {
  accountLogin: {
    errorMessage: '错误的用户名和密码！',
  },
  failure: '登录失败，请重试！',
  success: '登录成功！',
  user: {
    placeholder: '用户账号',
    required: '用户账号是必填项！',
    valid: '输入的用户账号无效！',
  },
  password: {
    placeholder: '密码',
    placeholderNew: '新密码',
    placeholderNew2: '确认密码',
    required: '密码是必填项！',
    validSame: '输入相同密码！',
    valid: '输入的密码无效！',
  },
  forgotPassword: '忘记密码 ?',
  submit: '登录',
  signOut: '退出登录',
  updatePsd: '修改密码',
  errorMessage: '错误的用户名和密码',
};
