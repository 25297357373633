export default {
  failure: 'Login failed, please try again!',
  success: 'Login successful!',
  user: {
    placeholder: 'user account',
    required: 'User account is required! ',
    valid: 'The entered user account is invalid! ',
  },
  password: {
    placeholder: 'Password',
    placeholderNew: 'New Password',
    placeholderNew2: 'Confirm Password',
    required: 'Please input your password!',
    validSame: 'Please input your same password!',
    valid: 'The input is not valid password!',
  },
  forgotPassword: 'Forget Password ?',
  submit: 'Sign in',
  signOut: 'Sign out',
  updatePsd: 'Reset password',
  errorMessage: 'Wrong username and password',
};
